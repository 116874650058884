package redux

import index.ALL_CLOSED
import index.State


fun changeReducer(state: State, action: RAction) =
    when (action) {
        is ChangeOpenedArticle -> {
            val openedArticle = action.openedArticle
            console.info("changeReducer: state.openedArticle = ${state.openedArticle}; openedArticle = $openedArticle")
            if (openedArticle != state.openedArticle)
            State(openedArticle) else State(ALL_CLOSED)}
        else -> state
    }
