package data

import kotlin.js.Date

class Article(
    val id: Int,
    val date: Date,
    val title: String,
    val text: String,
    val source: String,
    val comments: String
)

val articles: List<Article> = listOf(
    Article(
        1, Date(2016, 5, 24, 0, 0), "Как выросла тыква на кухонных отходах",
        "Хочу рассказать о своём способе выращивания тыквы. Я работаю, особенно заниматься огородом некогда, " +
                "и поэтому большая часть участка задернена. Решила я посадить тыквы прямо в дернине, так ка свободной " +
                "грядки не было, а времени специально раскапывать грядку тоже. Я поступила следующим образом:" +
                "Выкопала ямку примерно 30 x 30 см прямо в дернине." +
                "На самое дно положила верхний слой дернины прямо с травой и корнями (траву вниз головой, корни наверх)." +
                "Порубила это всё лопатой." +
                "Дальше ноу-нак - иоё собственное.", "Журнал 'Дачный сезон'","Не так и много..."
    ),
    Article(
        2, Date(2016, 5, 24, 15, 32), "Как я выращиваю свёклу",
        "Мы на участке каждый год выращиваем свёклу, хочу поделиться своим небольшим опытом. " +
                "Прореживаю всходы первый раз, когда на растении будет 2-3 листочка, оставляю между растениями 2-3 см." +
                "Через 2-3 недели прореживаю второй раз, оставив между всходами около 10 см." +
                "В период активного роста листьев и корнеплодов поливаю свёклу чаще. В это же время делаю полезные" +
                "подкормки минеральными и органическими удобрениями.", "Журнал 'Дачный сезон'","Тоже не так и много..."
    ),
    Article(
        3, Date(2016, 5, 3, 11, 25), "Севооборот: учитываем соседство",
        "Одни растения могут защищать другие или способствовать их росту и развитию. Защита от вредителей и болезней " +
                "являктся огромным плюсом смешанных посадок. Все эти свойства нужно учитывать при посеве и высадке культур." +
                "Особенно, когда культуры достигают своего товарного вида в разное время.", "Журнал 'Дачный сезон'","И опять не очень много..."
    )
)