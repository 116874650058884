package index

import app.app
import data.articles
import kotlinext.js.require
import kotlinext.js.requireAll
import redux.changeReducer
import redux.createStore
import redux.rEnhancer
import kotlin.browser.document

val store = createStore(
        ::changeReducer,
        State(1), //ALL_CLOSED),
        rEnhancer()
)

val rootDiv =
        document.getElementById("root")

fun render() = react.dom.render(rootDiv) {
    //hashRouter {
    app(articles, store)
    //}
}

fun main(args: Array<String>) {
    requireAll(require.context("src", true, js("/\\.css$/")))

//    render(document.getElementById("root")) {
//        app()
//    }

    render()
    store.subscribe {
        render()
    }
}
