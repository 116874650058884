package app

import components.articleListBuilder
import data.Article
import index.State
import org.w3c.dom.events.Event
import react.RBuilder
import react.RProps
import react.child
import react.dom.div
import react.dom.h1
import react.functionalComponent
import redux.ChangeOpenedArticle
import redux.RAction
import redux.Store
import redux.WrapperAction

interface AppProps : RProps {
    var articles: List<Article>
    var store: Store<State, RAction, WrapperAction>
}

fun fApp() =
        functionalComponent<AppProps> {
            val openedArticle = it.store.getState().openedArticle
            div("container-fluid") {
                h1 { +"Интересные статьи из собрания Ларистовой Ольги Андреевны" }
                articleListBuilder(it.articles, openedArticle, it.onClickArticle())
            }
        }

fun RBuilder.app(
        articles: List<Article>,
        store: Store<State, RAction, WrapperAction>
) =
        child(
                fApp()
        ) {
            attrs.articles = articles  //.lessons = lessons
            attrs.store = store
        }

fun AppProps.onClickArticle(): (Int) -> (Event) -> Unit =
        { newClickedArticle ->
            {
                store.dispatch(ChangeOpenedArticle(newClickedArticle))
            }
        }

/*package app

import react.*
import react.dom.*
import logo.*
import ticker.*

class App : RComponent<RProps, RState>() {
    override fun RBuilder.render() {
        div("App-header") {
            logo()
            h2 {
                +"Welcome to React with Kotlin"
            }
        }
        p("App-intro") {
            +"To get started, edit "
            code { +"app/App.kt" }
            +" and save to reload."
        }
        p("App-ticker") {
            ticker()
        }
    }
}

fun RBuilder.app() = child(App::class) {}*/
