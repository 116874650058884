package components

import data.Article
import index.ALL_OPENED
import kotlinx.html.id
import org.w3c.dom.events.Event
import react.RBuilder
import react.RProps
import react.child
import react.dom.div
import react.functionalComponent

interface ArticleListProps : RProps {
    var articles: List<Article>
    var openedArticle: Int
    var onClick: (Int) -> (Event) -> Unit
}

var fcArticleList = functionalComponent<ArticleListProps> {
    for ((index, article) in it.articles.withIndex())
        div {
            articleBuilder(article, it.openedArticle == ALL_OPENED || it.openedArticle == index, it.onClick(index))
            attrs.id = article.id.toString()
            console.info("Article list call articleBuilder with isOpened = ${it.openedArticle == ALL_OPENED || it.openedArticle == index}")
        }
}

fun RBuilder.articleListBuilder(articles: List<Article>, openedArticle: Int, onClick: (Int) -> (Event) -> Unit) =
    child(fcArticleList) {
        attrs.articles = articles
        attrs.openedArticle = openedArticle
        attrs.onClick = onClick
    }

