package components

import data.Article
import kotlinx.html.js.onClickFunction
import org.w3c.dom.events.Event
import react.*
import react.dom.*

interface ArticleProps : RProps {
    var article: Article
    var isOpened: Boolean
    var onClick: (Event) -> Unit
}

var fcArticle = functionalComponent<ArticleProps> {
    val buttonText = if (it.isOpened) "Cвернуть" else "Развернуть"
    div("card") {
        div("card-header") {
            h2 {
                +it.article.title
                button(classes = "btn btn-primary btn-lg float-right") {
                    attrs.onClickFunction = it.onClick
                    +buttonText
                }
            }
        }
        if (it.isOpened)
            div("card-body card-text") {
                +it.article.text
            }
    }
    br { }
}

fun RBuilder.articleBuilder(article: Article, isOpened: Boolean, onClick: (Event) -> Unit) =
    child(fcArticle) {
        attrs.article = article
        attrs.isOpened = isOpened
        attrs.onClick = onClick
    }

